import React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Header from './Header.tsx';
import Footer from './Footer.tsx';
import Layout from './Layout.tsx';
import CmsContent from './CmsContent.js';

const Privacy = () => {
  return (
    <CssVarsProvider>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Typography level="h2" component="h1" sx={{ mb: 2 }}>
            Privacy Policy
          </Typography>
          <Typography level="body1">
            <CmsContent page="Privacy & Cookie Policy" />
          </Typography>
        </Box>
        <Footer />
      </Box>
    </CssVarsProvider>
  );
};

export default Privacy;
