import React, { useEffect, useState } from 'react';
import Cms from '../lib/Cms.js';

const CmsContent = ({ page }) => {
    const [content, setContent] = useState();

    useEffect(() => {
        Cms.getContent(page).then(html => {
        setContent(html);
        });
    });

    return (
        <div dangerouslySetInnerHTML={{__html: content}} />
    )
};

export default CmsContent;