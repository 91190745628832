import React from 'react';
import { useState, useEffect, createRef, useImperativeHandle, useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useSession } from '../SessionContext.js';
import Header from './Header.tsx';
import Footer from './Footer.tsx';
import Layout from './Layout.tsx';
import { Card, IconButton, AspectRatio, CardContent, Stack, Select, Option, Grid, Container, CircularProgress, FormControl, FormLabel, Input, Divider, Checkbox, Modal, ModalDialog, ModalClose, FormHelperText } from '@mui/joy';
import { BookmarkAdd, DeleteOutline, Print, FileUpload, Image, EmailRounded } from '@mui/icons-material';
import moment from 'moment';
import OrientedImage from './OrientedImage.js';
import { OpenCvProvider } from 'opencv-react';

import bootsLogo from '../images/boots-logo.svg';
import snapfishLogo from '../images/snapfish-logo.png';
import PolaroidPile from './PolaroidPile.jsx';
import PlaceOrder from './PlaceOrder.js';
import { Link } from 'react-router-dom';

const Order = () => {
    const { session, logout, refreshSession } = useSession();
    const [loading, setLoading] = useState(false);
    const [placeOrderModalOpen, setPlaceOrderModalOpen] = useState(false);
    const [photos, setPhotos] = useState([]);

    const [firstName, setFirstName] = useState(session.user.data.account.firstname);
    const [lastName, setLastName] = useState(session.user.data.account.lastname);
    const [email, setEmail] = useState(session.user.data.account.email);

    const [line1, setLine1] = useState(session.user.data.payment.address.line1);
    const [line2, setLine2] = useState(session.user.data.payment.address.line2);
    const [city, setCity] = useState(session.user.data.payment.address.city);
    const [postcode, setPostcode] = useState(session.user.data.payment.address.postcode);
    const [country, setCountry] = useState(session.user.data.payment.address.country);

    const [otp, setOtp] = useState();
    const vaultIframe = createRef();
    const isMobile = false;

    const cssUrl = window.location.protocol + '//' + window.location.host + '/css/payment-form.css';
    const iframeUrl = `https://vault.automationcloud.net/forms/index.html?css=${cssUrl}&validateOnInput=on&otp=${otp}`;

    async function getOtp() {
        const options = {
            method: 'POST'
        };
        const res = await fetch('https://us157beq.run.nodescript.dev/api/payment/get-otp', options);
        return (await res.json()).id;
    }

    useEffect(() => {
        async function initCardForm() {
            const otp = await getOtp();
            setOtp(otp);
        }

        initCardForm();

        if (session) {
            const photos = session.images.map(img => ({ src: img.url, orientation: img.settings.orientation }));
            setPhotos(photos);
        }
    }, []);

    useImperativeHandle(vaultIframe, () => ({
        async submitForm() {
            return new Promise(resolve => {
                const receiveMessage = (message) => {
                    resolve(message.data);
                    window.removeEventListener('message', receiveMessage);
                }
                window.addEventListener('message', receiveMessage, false);
                vaultIframe.current.contentWindow.postMessage('vault.submit', '*');
            });
        }
    }));

    function destroy() {
        setLoading(false);
        setPlaceOrderModalOpen(false);
    }

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    function placeOrder() {
        setPlaceOrderModalOpen(true);
    }

    return (
        <CssVarsProvider>
            <Layout.Header>
                <Header />
            </Layout.Header>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, p: 3, mb: 13 }}>
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                        <Grid item>
                            <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                Place your order
                            </Typography>
                            <Typography level="p" component="p" sx={{ mb: 2 }}>
                                Please enter your address and payment details below to place your order
                            </Typography>
                        </Grid>
                        <Grid item>
                            {loading && (
                                <CircularProgress size='md' />
                            )}
                            <Button variant="solid" size="lg" color="primary" sx={{ ml: 2 }} href="/dashboard" component="a">
                                <Image sx={{ mr: 1 }} />
                                Edit images
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                        <Grid item sm={6} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Card>
                                <Box>
                                    <Typography level="title-lg">Personal information</Typography>
                                </Box>
                                <Divider />
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                                >
                                    <Stack spacing={2} sx={{ flexGrow: 1, width: '500px' }}>
                                        <Stack spacing={2} direction="row">
                                            <FormControl>
                                                <FormLabel>First name</FormLabel>
                                                <Input size="sm" placeholder="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Last name</FormLabel>
                                                <Input size="sm" placeholder="Last name" value={session.user.data.account.lastname} onChange={(e) => setLastName(e.target.value)} />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>Email</FormLabel>
                                                <Input
                                                    size="sm"
                                                    type="email"
                                                    startDecorator={<EmailRounded />}
                                                    placeholder="email"
                                                    defaultValue="siriwatk@test.com"
                                                    sx={{ flexGrow: 1 }}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                                >
                                    <Stack spacing={2} sx={{ flexGrow: 1, width: '500px' }}>
                                        <Stack spacing={2} direction="row">
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>Address line 1</FormLabel>
                                                <Input size="sm" placeholder="Address line 1" value={line1} onChange={(e) => setLine1(e.target.value)} />
                                            </FormControl>
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>Address line 2</FormLabel>
                                                <Input size="sm" placeholder="" value={line2} onChange={(e) => setLine2(e.target.value)} />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={2} direction="row">
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>City</FormLabel>
                                                <Input size="sm" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </FormControl>
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>Postcode</FormLabel>
                                                <Input size="sm" placeholder="Postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                            </FormControl>
                                        </Stack>
                                        <Stack spacing={2} direction="row">
                                            <FormControl sx={{ flexGrow: 1 }}>
                                                <FormLabel>Country</FormLabel>
                                                <Select value="United Kingdom">
                                                    <Option value="United Kingdom">United Kingdom</Option>
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Card>

                            <Card sx={{ mt: 3 }}>
                                <Box>
                                    <Typography level="title-lg">Payment details</Typography>
                                </Box>
                                <Divider />
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                                >
                                    <Stack spacing={2} sx={{ flexGrow: 1, width: '500px' }}>
                                        {otp ? (
                                            <iframe ref={vaultIframe} src={iframeUrl} width="100%" frameBorder={0} height={isMobile ? 300 : 220}></iframe>
                                        ) : ''}
                                    </Stack>
                                </Stack>
                            </Card>

                            <Stack spacing={3} mt={4} ml={2}>
                                <span>
                                    <Checkbox checked />
                                    <span style={{ marginLeft: '10px' }}>
                                        I agree to the <b>EzePix</b> <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy policy</Link>.
                                    </span>
                                </span>

                                <span>
                                    <Checkbox checked />
                                    <span style={{ marginLeft: '10px' }}>
                                        I agree to the <b>Boots Photo</b> <Link to="/terms">terms and conditions</Link> and <Link to="/privacy">privacy policy</Link>.
                                    </span>
                                </span>

                                <Stack direction="row">
                                    <Typography>
                                        Clicking "Confirm Order" will place your order with <b>Boots Photo</b> who will handle the printing and delivery of your prints.
                                    </Typography>
                                </Stack>
                            </Stack>

                        </Grid>
                        <Grid item sm={6} mt={8} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <PolaroidPile images={photos.slice(0, 25)} multiplier={Math.min(2, Math.max(1, 15 / photos.length))} animate={false} containerWidth={450} containerHeight={800} squashFactor={1} maxAngle={30} />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            width: '100%',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            bgcolor: '#fff',
                            color: 'white',
                            py: 3,
                            px: 0,
                            boxShadow: '0 -1px 5px rgba(0,0,0,0.1)',
                            zIndex: 1000
                        }}
                    >
                        <Container maxWidth="100%">
                            <Grid container justifyContent="space-between" alignItems="center" sx={{ maxWidth: '100%', width: '100%', padding: '0 12px' }}>
                                <Grid item>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Select value="boots" size="lg" sx={{ mr: 4 }} startDecorator={<img src={bootsLogo} height={25} />}>
                                            <Option value="boots">
                                                <img src={bootsLogo} height={25} />
                                                Boots Photo
                                            </Option>
                                            <Option value="snapfish">
                                                <img src={snapfishLogo} height={25} />
                                                Snapfish
                                            </Option>
                                        </Select>
                                        <Typography variant="body2" sx={{ mr: 1 }}>
                                            <b>Printing:</b> £{session.estimatedPrice.photos.value.toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2" sx={{ mr: 1 }}>
                                            <b>Postage:</b> £{session.estimatedPrice.delivery.value.toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2" sx={{ mr: 1 }}>
                                            <b>Total:</b> £{(session.estimatedPrice.photos.value + session.estimatedPrice.delivery.value).toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="solid"
                                        size="lg"
                                        color="primary"
                                        sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                                        onClick={() => placeOrder()}
                                    >
                                        Confirm Order <Print sx={{ ml: 1 }} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                </Box>
            </Box>

            {placeOrderModalOpen && (
                <PlaceOrder jobId={session.activeJob.id} onClose={() => setPlaceOrderModalOpen(false)} />
            )}
        </CssVarsProvider>
    );
};

export default Order;
